.menu-item{

	color:#23483E;
		// background-color: #3b4c4b;
		// display: flex;
		// flex-direction: column;
		// justify-content: flex-start;
		// width: 14%;
		// height: 100%;
		// overflow: hidden;
		// top: 0;
		// left: 0;
		// position: fixed;
		@media screen and (max-width: 768px){
			
			display: block;
			height: 50px;
			position: relative;
			background-color: #23483E;
			color:white;
			width:100%

			
		}
		@media screen and (min-width: 768px){
			padding-right:15px;
			// padding-left:7.5px;
			margin-right:7.5px !important;
			margin-left:7.5px !important;
			
		}
}
.navbar{
	width:100%;
	text-align: center;
	color:#23483E;
	margin-bottom: 10px;
	@media screen and (max-width: 768px){
		width: 70%;
		border-radius: 10px;
		float:right;
		overflow: hidden;
		margin-top:20px;
		height: 100%;
		margin-right: 20px;	
	}
	
	:not(:last-child){
		border-right-width: 1px;
		border-right-style: solid;
	}
}